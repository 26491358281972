import { InputWrapper, useMantineTheme } from "@mantine/core";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useCallback, useMemo, useState } from "react";

export function CustomCardElement() {
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);
  const theme = useMantineTheme();
  const inputStyle = useMemo(
    () => ({
      backgroundColor: "white",
      lineHeight: "38px",
      "::placeholder": {
        color: theme.colors.gray[5],
      },
    }),
    [theme]
  );

  const borderColor = isError
    ? theme.colors.red[5]
    : isFocused
    ? theme.colors.blue[5]
    : theme.colors.gray[4];

  const handleCheckForError = useCallback((e: StripeCardElementChangeEvent) => {
    setIsError(!!e.error);
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <InputWrapper label="Card" required>
      <div
        style={{
          border: `1px solid ${borderColor}`,
          padding: "0 8px",
          borderRadius: "4px",
          background: theme.white,
          transition: "border-color 50ms ease-in-out",
        }}
      >
        <CardElement
          options={{
            style: {
              base: inputStyle,
            },
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleCheckForError}
        />
      </div>
    </InputWrapper>
  );
}
