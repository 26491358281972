import { Button, Table, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useCallback, useMemo } from "react";
import { PhoneCall } from "tabler-icons-react";
import { useGetCalls } from "../../hooks/useGetCalls";
import { useMakeCall } from "../../hooks/useMakeCall";
import { centsToDollar } from "../../utils/currency";

export function InfluencerCompletedCalls() {
  const { data: calls } = useGetCalls();
  const { mutate: makeCall } = useMakeCall();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm}px)`,
    false
  );

  const completeCalls = useMemo(
    () =>
      calls
        ?.filter((call) => !!call.influencerStartDate && !!call.endDate)
        .sort(
          (a, b) =>
            new Date(b.callStartDate).getTime() -
            new Date(a.callStartDate).getTime()
        ) || [],
    [calls]
  );

  const handlePhoneCall = useCallback(
    (followerId: string) => {
      makeCall(followerId);
    },
    [makeCall]
  );

  return (
    <Table highlightOnHover fontSize="xs">
      <thead>
        <tr>
          <th>Name</th>
          <th>Date</th>
          {!!isMobile && <th>Length</th>}
          <th>Earned</th>
          <th>Call</th>
        </tr>
      </thead>
      <tbody>
        {completeCalls.map((call) => (
          <tr key={call.callId}>
            <td>{call.fullName}</td>
            <td>{new Date(call.influencerStartDate).toLocaleDateString()}</td>
            {!!isMobile && (
              <td>{getLengthOfTime(call.influencerStartDate, call.endDate)}</td>
            )}
            <td>
              $
              {getEarnings(
                getTimeDifference(call.influencerStartDate, call.endDate),
                call.rate
              )}
            </td>
            <td>
              <Button onClick={() => handlePhoneCall(call.followerId)}>
                <PhoneCall />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;

function getTimeDifference(start: string, end: string) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const difference = endDate.getTime() - startDate.getTime();
  return difference;
}

function getLengthOfTime(start: string, end: string) {
  const diffTime = getTimeDifference(start, end);
  const diffMinutes = Math.floor(diffTime / ONE_MINUTE);
  const diffSeconds = Math.ceil(
    (diffTime - diffMinutes * ONE_MINUTE) / ONE_SECOND
  );

  return `${diffMinutes}m ${diffSeconds}s`;
}

function getEarnings(timeInMs: number, rate: number) {
  const timeInSeconds = timeInMs / ONE_SECOND;
  if (timeInSeconds < 30) {
    return 0;
  }
  return centsToDollar(Math.ceil(timeInMs / ONE_MINUTE) * rate);
}
