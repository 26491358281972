import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  InputWrapper,
  List,
  LoadingOverlay,
  Modal,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { AddressBook } from "tabler-icons-react";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { UseFormTypes, useValidateForms } from "../../hooks/useValidateForms";
import { PhoneCountryCodeSelect } from "../PhoneCountryCodeSelect";
import { useGetCheckoutInfluencer } from "../../hooks/useGetCheckoutInfluencer";
import { useCopyText } from "./useCopyText";
import { CustomCardElement } from "./CustomCardElement";
import { centsToDollar } from "../../utils/currency";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

export function Checkout() {
  const {
    isLoading: isQueryLoading,
    error,
    data: influencer,
  } = useGetCheckoutInfluencer();

  const params = useParams();

  if (isQueryLoading) {
    return null;
  }

  if (error || !Object.keys(influencer || {}).length || !influencer) {
    return (
      <div>
        <h1>This influencer does not exist</h1>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        friendlyId={params.friendlyId || ""}
        rate={influencer.rate}
      />
    </Elements>
  );
}

function CheckoutForm({
  friendlyId,
  rate,
}: {
  friendlyId: string;
  rate: number;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useMantineTheme();
  const form = useValidateForms({ name: "", phone: "", email: "" });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isQueryLoading, data: influencer } =
    useGetCheckoutInfluencer();

  const copyText = useCopyText();

  const isMobile = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm}px)`,
    false
  );

  const [isSignedUp, setIsSignedUp] = useState(false);

  const handleSubmit = async (values: UseFormTypes) => {
    if (!stripe || !elements) {
      return;
    }

    setIsSubmitting(true);

    const { name, email, phone: formattedPhone } = values;
    const phone = formattedPhone?.replace(/[^\d]/g, "");

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    if (error) {
      // do nothing
    } else {
      await fetch(`${process.env.REACT_APP_API_URL}/followers`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          friendlyId,
          paymentMethodId: paymentMethod.id,
        }),
      });
      setIsSignedUp(true);
    }

    setIsSubmitting(false);
  };

  const { classes } = createStyles((theme) => ({
    container: {
      width: "100%",
      maxWidth: "800px",
      padding: isMobile ? theme.spacing.lg : 0,
    },
  }))();

  return (
    <>
      <LoadingOverlay visible={isQueryLoading} />

      <Modal
        opened={isSignedUp}
        onClose={() => {}}
        withCloseButton={false}
        centered
        size="md"
        padding="xl"
      >
        <Center style={{ flexDirection: "column" }}>
          <Title order={3}>You're on the list!</Title>
          <Space h="xl" />
          <Text align="center" color={theme.colors.gray[8]}>
            You've been added to {influencer?.fullName}'s call list. Please add
            the following contact info to your phone so you don't miss the call:
          </Text>
          <Space h="sm" />
          <Space h="xs" />
          <Button
            fullWidth
            component="a"
            href="/vcard.vcf"
            leftIcon={<AddressBook size="16" />}
          >
            Click here to add contact
          </Button>
          <Space h="lg" />
          <Text align="center" size="xs" color={theme.colors.gray[6]}>
            You will only be charged if you pick up the call and talk to{" "}
            {influencer?.fullName} for more than 30 seconds. There will be three
            call attempts before you're automatically removed from the list.
          </Text>
        </Center>
      </Modal>

      <Group
        grow
        spacing={0}
        sx={(theme) => ({
          height: isMobile ? "100%" : undefined,
          width: isMobile ? undefined : "100%",
        })}
        align={isMobile ? "start" : "center"}
        direction={isMobile ? "row" : "column"}
      >
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.colors.grape[9],
            height: "100%",
            width: "100%",
            padding: theme.spacing.lg,
            color: theme.white,
          })}
          align={isMobile ? "start" : "center"}
        >
          <Stack spacing="xs" align="start" className={classes.container}>
            <Title>Speak to {influencer?.fullName}</Title>
            <Group spacing={0}>
              <Text weight="bold" size="lg" color={theme.colors.grape[2]}>
                ${centsToDollar(influencer?.rate || 0)}/minute
              </Text>
            </Group>
            <Space h="xs" />
            {copyText}
          </Stack>
        </Stack>
        <Stack
          sx={(theme) => ({
            height: "100%",
            width: "100%",
            padding: theme.spacing.lg,
          })}
          align={isMobile ? "start" : "center"}
        >
          <form
            className={classes.container}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <TextInput
              required
              label="Full Name"
              placeholder="John Doe"
              {...form.getInputProps("name")}
              onChange={(e) => {
                form.clearFieldError("name");
                form.setFieldValue("name", e.currentTarget.value);
              }}
              onBlur={() => form.validateField("name")}
            />
            <TextInput
              required
              label="Email"
              placeholder="john@doe.org"
              {...form.getInputProps("email")}
              onChange={(e) => {
                form.clearFieldError("email");
                form.setFieldValue("email", e.currentTarget.value);
              }}
              onBlur={() => form.validateField("email")}
            />
            <InputWrapper
              label="Phone"
              error={form.getInputProps("phone").error}
            >
              <Group>
                <Select
                  itemComponent={PhoneCountryCodeSelect}
                  data={[
                    {
                      countryEmoji: "🇺🇸",
                      value: "1",
                      label: "+1",
                      description: "USA",
                    },
                  ]}
                  value="1"
                  style={{ flexBasis: "96px" }}
                />
                <TextInput
                  required
                  placeholder="(555) 555-5555"
                  {...form.getInputProps("phone")}
                  error={!!form.getInputProps("phone").error}
                  onChange={(e) => {
                    const formattedNumber = formatPhoneNumber(
                      e.currentTarget.value
                    );
                    form.clearFieldError("phone");
                    form.setFieldValue("phone", formattedNumber);
                  }}
                  onBlur={() => form.validateField("phone")}
                  style={{ flex: 1 }}
                />
              </Group>
            </InputWrapper>

            <CustomCardElement />

            <Stack
              sx={(theme) => ({
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "4px",
              })}
              spacing={4}
            >
              <Group position="apart">
                <Text weight="bold">Total now:</Text>
                <Text weight="bold">$0.00</Text>
              </Group>
              <Group position="apart">
                <Text color={theme.colors.gray[6]} size="sm">
                  When called:
                </Text>
                <Text color={theme.colors.gray[6]} size="sm">
                  ${centsToDollar(rate)}/minute
                </Text>
              </Group>
              <List style={{ color: theme.colors.gray[5] }} size="xs">
                <List.Item>
                  You will never be billed unless you accept the call.
                </List.Item>
                <List.Item>
                  Billing begins after first 30 seconds of call.
                </List.Item>
                <List.Item>Three call attempts will be made.</List.Item>
              </List>
              <Divider mt="md" mb="md" color={theme.colors.gray[3]} />
              <Group position="apart">
                <Text weight="bold" size="lg">
                  Total:
                </Text>
                <Text weight="bold" size="lg">
                  $0.00
                </Text>
              </Group>
            </Stack>
            <Button
              loading={isSubmitting}
              size="xl"
              onClick={form.onSubmit(handleSubmit)}
            >
              Sign Up
            </Button>
          </form>
        </Stack>
      </Group>
    </>
  );
}
