import { MouseEvent, useCallback, useRef, useState } from "react";
import {
  ActionIcon,
  Container,
  Group,
  InputWrapper,
  Paper,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { Copy, InfoCircle } from "tabler-icons-react";
import { useGetUser } from "../../hooks/useGetUser";

export function InfluencerLink() {
  const theme = useMantineTheme();
  const { data: userData } = useGetUser();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isTooltipShowing, setIsTooltipShowing] = useState<boolean>(false);
  const copiedTimeoutRef = useRef<number>();
  const tooltipRef = useRef<number>();

  const signupUrl = `${window.location.origin}/signup/${userData?.friendlyId}`;

  const handleCopySignupUrl = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    clearTimeout(copiedTimeoutRef.current);

    navigator.clipboard.writeText(signupUrl);
    setIsCopied(true);

    copiedTimeoutRef.current = window.setTimeout(
      () => setIsCopied(false),
      2000
    );
  };

  const handleShowTooltip = useCallback(() => {
    clearTimeout(tooltipRef.current);
    setIsTooltipShowing(true);
    tooltipRef.current = window.setTimeout(
      () => setIsTooltipShowing(false),
      2000
    );
  }, []);

  if (!userData?.friendlyId) {
    return null;
  }

  return (
    <Container p={0}>
      <InputWrapper
        label={
          <Group spacing="xs">
            <Text size="sm">Your signup link</Text>
            <Tooltip
              opened={isTooltipShowing}
              label="Share this link with your followers"
              withArrow
              transition="pop"
            >
              <InfoCircle
                onClick={handleShowTooltip}
                style={{ transform: "translateY(4px)", cursor: "pointer" }}
                color={theme.colors.gray[7]}
              />
            </Tooltip>
          </Group>
        }
      >
        <Paper radius="md" p="xs">
          <Group position="apart" spacing={0}>
            <Text
              size="sm"
              style={{
                flex: 1,
                overflowX: "scroll",
              }}
            >
              {signupUrl}
            </Text>
            <Tooltip
              opened={isCopied}
              label="Copied!"
              withArrow
              transition="pop"
            >
              <ActionIcon onClick={handleCopySignupUrl}>
                <Copy color={theme.colors.gray[7]} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Paper>
      </InputWrapper>
    </Container>
  );
}
