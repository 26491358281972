import {
  Anchor,
  Button,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { webAuth } from "../../utils/auth0";
import { useCallback, useState } from "react";
import { z } from "zod";
import { useForm, zodResolver } from "@mantine/form";

type SubmitArgs = { email: string; password: string };

const signupSchema = z.object({
  email: z.string().email({ message: "Invalid email" }),
  password: z.string(),
});

export function AuthLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [invalidLoginMessage, setInvalidLoginMessage] = useState("");
  const theme = useMantineTheme();

  const form = useForm({
    schema: zodResolver(signupSchema),
    initialValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = useCallback(async ({ email, password }: SubmitArgs) => {
    setIsLoading(true);

    webAuth.login(
      {
        username: email,
        password: password,
      },
      (error) => {
        if (error) {
          setInvalidLoginMessage("Wrong email or password");
          setIsLoading(false);
        }
      }
    );
  }, []);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xl">
        <Stack spacing="xl">
          <TextInput
            label="Email"
            required
            {...form.getInputProps("email")}
            error={form.getInputProps("email").error || invalidLoginMessage}
            onInput={() => setInvalidLoginMessage("")}
          />
          <TextInput
            label="Password"
            type="password"
            required
            {...form.getInputProps("password")}
            error={
              form.getInputProps("password").error || !!invalidLoginMessage
            }
            onInput={() => setInvalidLoginMessage("")}
          />
          <Button loading={isLoading} type="submit">
            Log In
          </Button>
          <Group spacing="xs">
            <Text size="sm" color={theme.colors.gray[6]}>
              Forgot your password?
            </Text>
            <Anchor href="/auth/password" size="sm">
              Reset password
            </Anchor>
          </Group>
        </Stack>
      </Stack>
    </form>
  );
}
