import { PhoneCall } from "tabler-icons-react";
import {
  Badge,
  Button,
  Center,
  ColorSwatch,
  Container,
  Group,
  Loader,
  Paper,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { useCallStatus, StatusCode } from "../../hooks/useCallStatus";
import { useGetPendingFollowers } from "../../hooks/useGetPendingFollowers";
import { useGetNextCall } from "../../hooks/useGetNextCall";

export function InfluencerCallButton() {
  const { mutate: getNextCall, isLoading: isGettingNextCall } =
    useGetNextCall();
  const { status, statusCode, isCalling } = useCallStatus(isGettingNextCall);
  const theme = useMantineTheme();

  const { data: followers, dataUpdatedAt: followersFetchedDate } =
    useGetPendingFollowers(isCalling);
  const callsInQueue = followers?.length || 0;

  const handleCallNext = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    getNextCall();
  };

  const statusCodeColor =
    statusCode === StatusCode.SUCCESS
      ? theme.colors.green[6]
      : statusCode === StatusCode.PENDING
      ? theme.colors.yellow[6]
      : statusCode === StatusCode.FAIL
      ? theme.colors.red[6]
      : undefined;

  const customStatusMessage =
    callsInQueue === 0 ? "No calls in queue" : "Click to start call";

  const customStatusCodeColor =
    callsInQueue === 0 ? theme.colors.red[6] : theme.colors.green[6];

  return (
    <Container size="sm">
      <Center>
        <Stack sx={() => ({ width: "100%" })}>
          <Paper shadow="xl" radius="xl">
            <Button
              radius="md"
              color="grape"
              loading={isCalling || isGettingNextCall}
              disabled={callsInQueue === 0}
              loaderProps={{
                size: "xl",
                style: {
                  position: "absolute",
                  left: 0,
                  right: 0,
                  margin: "auto",
                },
              }}
              onClick={handleCallNext}
              fullWidth
              styles={(theme) => ({
                root: {
                  height: 300,
                  borderBottom:
                    isCalling || isGettingNextCall
                      ? 0
                      : `6px solid ${theme.colors.grape[7]}`,
                },
                leftIcon: {
                  position: "absolute",
                  left: 0,
                  right: 0,
                },
              })}
              pb="md"
            >
              {followersFetchedDate ? (
                <Stack>
                  <PhoneCall size={160} />

                  <Group>
                    <Badge
                      sx={(theme) => ({
                        position: "absolute",
                        bottom: theme.spacing.lg,
                        right: theme.spacing.lg,
                        backgroundColor:
                          callsInQueue === 0
                            ? theme.colors.gray[0]
                            : theme.colors.grape[1],
                        color:
                          callsInQueue === 0
                            ? theme.colors.gray[6]
                            : theme.colors.grape[9],
                      })}
                    >
                      {callsInQueue}
                    </Badge>
                    <Badge
                      sx={(theme) => ({
                        position: "absolute",
                        bottom: theme.spacing.lg,
                        left: theme.spacing.lg,
                        maxWidth: `calc(100% - 80px)`,
                        backgroundColor:
                          callsInQueue === 0
                            ? theme.colors.gray[0]
                            : theme.colors.grape[1],
                        color:
                          callsInQueue === 0
                            ? theme.colors.gray[6]
                            : theme.colors.grape[9],
                      })}
                    >
                      <Group noWrap>
                        <Group spacing={0}>
                          <ColorSwatch
                            color={statusCodeColor || customStatusCodeColor}
                            size={10}
                          />
                        </Group>
                        {status || customStatusMessage}
                      </Group>
                    </Badge>
                  </Group>
                </Stack>
              ) : (
                <Loader />
              )}
            </Button>
          </Paper>
        </Stack>
      </Center>
    </Container>
  );
}
