import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "react-query";
import { Call } from "../types/Call";

export function useGetCall() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const call = queryClient.getQueryData<Call>("call");
  const callId = call?.callId;
  const callEndDate = call?.endDate;

  return useQuery<Call>(
    "call",
    async () => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        // do nothing
      }

      if (!callId) {
        return null;
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/calls/${callId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const [call] = await res.json();
      return call;
    },
    {
      refetchInterval: callEndDate ? 0 : 5000,
    }
  );
}
