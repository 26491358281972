import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { dollarToCents } from "../utils/currency";

type Values = {
  name?: string;
  email?: string;
  rate?: number;
  phone?: string;
};

type UseUpdateUserProps = {
  isRateInCents?: boolean;
};

export function useUpdateUser({ isRateInCents = true }: UseUpdateUserProps) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(async (values: Values) => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      // do nothing
    }

    const { name, email, rate, phone: formattedPhone } = values;
    const phone = formattedPhone?.replace(/[^\d]/g, "");

    const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        rate: isRateInCents ? rate : dollarToCents(rate || 0),
        name,
        email,
        phone,
      }),
    });
    return res.json();
  });
}
