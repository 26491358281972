import { Button, Stack, Text, TextInput, useMantineTheme } from "@mantine/core";
import { webAuth } from "../../utils/auth0";
import { useCallback, useState } from "react";
import { z } from "zod";
import { useForm, zodResolver } from "@mantine/form";

type SubmitArgs = { email: string; password: string };

const signupSchema = z.object({
  email: z.string().email({ message: "Invalid email" }),
});

export function AuthForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const theme = useMantineTheme();

  const form = useForm({
    schema: zodResolver(signupSchema),
    initialValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = useCallback(async ({ email }: SubmitArgs) => {
    setIsLoading(true);

    webAuth.changePassword(
      {
        connection: "Username-Password-Authentication",
        email,
      },
      () => {
        setIsDone(true);
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xl">
        <Stack spacing="xl">
          <TextInput label="Email" required {...form.getInputProps("email")} />
          <Button loading={isLoading} type="submit">
            Reset Password
          </Button>
          {isDone && (
            <Text color={theme.colors.green[6]} size="sm">
              Check your email for a reset link.
            </Text>
          )}
        </Stack>
      </Stack>
    </form>
  );
}
