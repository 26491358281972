import { Button, Stack, TextInput } from "@mantine/core";
import { webAuth } from "../../utils/auth0";
import { useCallback, useState } from "react";
import { z } from "zod";
import { useForm, zodResolver } from "@mantine/form";
import { useCheckInviteCode } from "../../hooks/useCheckInviteCode";

type SubmitArgs = { email: string; password: string; inviteCode: string };

const signupSchema = z.object({
  email: z.string().email({ message: "Invalid email" }),
  password: z
    .string()
    .min(6, { message: "Password should have at least 6 letters" }),
  inviteCode: z.string(),
});

export function AuthRegister() {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidSignup, setIsInvalidSignup] = useState(false);
  const [isInviteCodeError, setIsInviteCodeError] = useState(false);
  const { mutateAsync: checkInviteCode } = useCheckInviteCode();

  const form = useForm({
    schema: zodResolver(signupSchema),
    initialValues: {
      email: "",
      password: "",
      inviteCode: "",
    },
  });

  const handleSubmit = useCallback(
    async ({ email, password, inviteCode }: SubmitArgs) => {
      setIsLoading(true);

      // check invite code
      const response = await checkInviteCode(inviteCode);

      if (response.error) {
        setIsInviteCodeError(true);
        setIsLoading(false);

        return;
      }

      // then sign up through auth0
      webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          email: email,
          password: password,
        },
        (error) => {
          if (!error) {
            webAuth.login(
              {
                username: email,
                password: password,
              },
              () => {}
            );
          } else {
            setIsInvalidSignup(true);
            setIsLoading(false);
          }
        }
      );
    },
    [checkInviteCode]
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xl">
        <Stack spacing="xl">
          <TextInput
            label="Email"
            required
            {...form.getInputProps("email")}
            error={form.getInputProps("email").error || isInvalidSignup}
            onInput={() => setIsInvalidSignup(false)}
          />
          <Stack spacing="xs">
            <TextInput
              label="Password"
              type="password"
              required
              {...form.getInputProps("password")}
            />
          </Stack>
          <TextInput
            label="Invite Code"
            required
            error={isInviteCodeError}
            {...form.getInputProps("inviteCode")}
            onInput={() => setIsInviteCodeError(false)}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          <Button loading={isLoading} type="submit">
            Sign Up
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
