import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Call } from "../types/Call";

export function useGetCalls() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const calls = queryClient.getQueryData<Call[]>("calls");

  useEffect(() => {
    const activeCall = calls?.find((call) => call.endDate == null);
    if (activeCall) {
      queryClient.setQueryData("call", activeCall);
    }
  }, [calls, queryClient]);

  return useQuery<Call[]>("calls", async () => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      // do nothing
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/calls`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.json();
  });
}
