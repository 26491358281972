import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { Follower } from "../types/Follower";

export function useGetPendingFollowers(isCalling: boolean) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Follower[]>(
    "followers",
    async () => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        // do nothing
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/followers/pending`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.json();
    },
    {
      refetchInterval: (isCalling ? 5 : 10) * 1000,
    }
  );
}
