import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

type Influencer = {
  fullName: string;
  rate: number;
  signupCopy: string;
};

export function useGetCheckoutInfluencer() {
  const params = useParams();

  return useQuery<Influencer, Error>("influencer", async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/influencers/friendly_id/${params.friendlyId}`
    );
    return res.json();
  });
}
