import {
  AppShell,
  Box,
  Container,
  Modal,
  Space,
  Table,
  Title,
} from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { useGetAdminPaymentsOwed } from "../../hooks/useGetAdminPaymentsOwed";
import { centsToDollar } from "../../utils/currency";

type PayoutRow = {
  influencerId: string;
  influencerFullName: string;
  influencerEmail: string;
  followerFullName: string;
  callEndDate: string;
  earnings: number;
};

type TableDataRow = {
  [key: string]: {
    earnings: number;
    name: string;
    email: string;
    influencerId: string;
    items: PayoutRow[];
  };
};

type TableData = {
  current: TableDataRow;
  oneMonthAgo: TableDataRow;
  twoMonthsAgo: TableDataRow;
  other: TableDataRow;
};

const OUR_SHARE_PCT = 0.25;

function getLastDayOfMonth(monthsAgo: number = 0) {
  const today = new Date();
  const lastDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1 - monthsAgo,
    0
  );
  return lastDayOfMonth.toLocaleDateString();
}

function formatAmountOwed(amountOwed: number) {
  const adjustedAmountOwed = amountOwed - amountOwed * OUR_SHARE_PCT;
  return centsToDollar(adjustedAmountOwed);
}

function formatAmountEarned(amountEarned: number) {
  return centsToDollar(amountEarned);
}

export function AdminScreen() {
  const [influencer, setInfluencer] = useState<{
    monthKey: string;
    influencerId: string;
  } | null>(null);
  const { data } = useGetAdminPaymentsOwed();

  const tableData = useMemo(() => {
    const value = data?.reduce(
      (acc: TableData, item: PayoutRow) => {
        const dateNow = new Date();
        const currentMonth = dateNow.getMonth();
        const lastOneMonth = dateNow.getMonth() - 1;
        const lastTwoMonth = dateNow.getMonth() - 2;

        const {
          influencerFullName: name,
          influencerEmail: email,
          callEndDate,
          influencerId,
        } = item;
        const itemMonth = new Date(callEndDate).getMonth();
        const monthKey =
          itemMonth === currentMonth
            ? "current"
            : itemMonth === lastOneMonth
            ? "oneMonthAgo"
            : itemMonth === lastTwoMonth
            ? "twoMonthsAgo"
            : "other";

        const user = acc?.[monthKey]?.[influencerId] || {};

        const items = [...(user.items || []), item];
        const earnings = (user.earnings || 0) + item.earnings;

        acc = {
          ...acc,
          [monthKey]: {
            ...acc?.[monthKey],
            [influencerId]: {
              earnings,
              name,
              email,
              items,
              influencerId,
            },
          },
        };

        return acc;
      },
      {
        current: {},
        oneMonthAgo: {},
        twoMonthsAgo: {},
      }
    );

    return value;
  }, [data]);

  const handleOpenModal = useCallback(
    (monthKey: string, influencerId: string) => {
      setInfluencer({ monthKey, influencerId });
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setInfluencer(null);
  }, []);

  return (
    <AppShell
      padding="xl"
      fixed
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[3],
        },
      })}
    >
      <Modal
        opened={!!influencer?.influencerId}
        onClose={handleCloseModal}
        withCloseButton
        centered
        size="xl"
        padding="xl"
      >
        <Title order={3}>
          {
            tableData?.[influencer?.monthKey || ""]?.[
              influencer?.influencerId || ""
            ]?.name
          }
          's earnings
        </Title>
        <Space h="xl" />
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Earnings</th>
              <th>Adjusted (owed)</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.[influencer?.monthKey || ""]?.[
              influencer?.influencerId || ""
            ]?.items.map((item: PayoutRow) => (
              <tr key={item.callEndDate}>
                <td>{item.followerFullName}</td>
                <td>{item.callEndDate}</td>
                <td>${formatAmountEarned(item.earnings)}</td>
                <td>${formatAmountOwed(item.earnings)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>

      <Box>
        <Container mb="xl">
          <Title order={1}>Payouts</Title>
        </Container>

        <Space h="xl" />

        <Container mb="xl">
          <Title order={2} mb="lg">
            {getLastDayOfMonth()} (in progress)
          </Title>
          <Table>
            <thead>
              <tr>
                <th>Earnings</th>
                <th>Adjusted (owed)</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(tableData?.current || {})?.map(
                ([_, influencer]: [any, any]) => (
                  <tr
                    key={influencer.email}
                    onClick={() =>
                      handleOpenModal("current", influencer.influencerId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>${formatAmountEarned(influencer.earnings)}</td>
                    <td>${formatAmountOwed(influencer.earnings)}</td>
                    <td>{influencer.name}</td>
                    <td>{influencer.email}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Container>

        <Space h="xl" />

        <Container mb="xl">
          <Title order={2}>{getLastDayOfMonth(1)}</Title>
          <Table>
            <thead>
              <tr>
                <th>Earnings</th>
                <th>Adjusted (owed)</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(tableData?.["oneMonthAgo"] || {})?.map(
                ([_, influencer]: [any, any]) => (
                  <tr
                    key={influencer.email}
                    onClick={() =>
                      handleOpenModal("oneMonthAgo", influencer.influencerId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>${formatAmountEarned(influencer.earnings)}</td>
                    <td>${formatAmountOwed(influencer.earnings)}</td>
                    <td>{influencer.name}</td>
                    <td>{influencer.email}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Container>

        <Space h="xl" />

        <Container mb="xl">
          <Title order={2}>{getLastDayOfMonth(2)}</Title>
          <Table>
            <thead>
              <tr>
                <th>Earnings</th>
                <th>Adjusted (owed)</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(tableData?.["twoMonthsAgo"] || {})?.map(
                ([_, influencer]: [any, any]) => (
                  <tr
                    key={influencer.email}
                    onClick={() =>
                      handleOpenModal("twoMonthsAgo", influencer.influencerId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>${formatAmountEarned(influencer.earnings)}</td>
                    <td>${formatAmountOwed(influencer.earnings)}</td>
                    <td>{influencer.name}</td>
                    <td>{influencer.email}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Container>
      </Box>
    </AppShell>
  );
}
