import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

export function useGetAdminPaymentsOwed() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery("adminPaymentsOwed", async () => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      // do nothing
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/payments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const calls = await res.json();
    return calls;
  });
}
