import auth0 from "auth0-js";

export const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  redirectUri: window.location.origin,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: "token id_token",
  overrides: {
    __token_issuer: process.env.REACT_APP_AUTH0_AUTHORIZATION_SERVER_ISSUER,
  },
});
