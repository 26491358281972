import { Group, Text } from "@mantine/core";
import { forwardRef } from "react";

type ItemProps = {
  countryEmoji: string;
  label: string;
  description: string;
  [key: string]: any;
};

export const PhoneCountryCodeSelect = forwardRef<HTMLDivElement, ItemProps>(
  ({ countryEmoji, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Text>{countryEmoji}</Text>

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);
