import { List, Text, useMantineTheme } from "@mantine/core";
import { useMemo } from "react";
import { CircleCheck } from "tabler-icons-react";
import { useGetCheckoutInfluencer } from "../../hooks/useGetCheckoutInfluencer";

export function useCopyText() {
  const { data: influencer } = useGetCheckoutInfluencer();

  const theme = useMantineTheme();

  return useMemo(() => {
    const element = document.createElement("div");
    element.innerHTML = influencer?.signupCopy || "";

    const replaceHtmlWithJsx = (e: HTMLCollection) => {
      return Array.from(e).map((child) => {
        if (child.nodeName === "P") {
          return (
            <Text
              key={(child as HTMLElement).innerText}
              size="lg"
              mb="md"
              color={theme.colors.grape[0]}
            >
              <div dangerouslySetInnerHTML={{ __html: child.innerHTML }} />
            </Text>
          );
        }

        if (child.nodeName === "UL") {
          return (
            <List
              key={(child as HTMLElement).innerText}
              style={{ color: theme.colors.grape[0] }}
              size="xl"
              spacing="md"
              mb="md"
              icon={<CircleCheck size={32} />}
            >
              {replaceHtmlWithJsx(child.children)}
            </List>
          );
        }

        if (child.nodeName === "LI") {
          return (
            <List.Item key={(child as HTMLElement).innerText}>
              <div dangerouslySetInnerHTML={{ __html: child.innerHTML }} />
            </List.Item>
          );
        }

        return child.innerHTML;
      });
    };

    return replaceHtmlWithJsx(element.children);
  }, [theme.colors.grape, influencer?.signupCopy]);
}
