import { useMutation } from "react-query";

export function useCheckInviteCode() {
  return useMutation(async (inviteCode: string) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/code?inviteCode=${inviteCode}`
    );
    return res.json();
  });
}
