import { Button, Table } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { PhoneCall } from "tabler-icons-react";
import { useGetCalls } from "../../hooks/useGetCalls";
import { useMakeCall } from "../../hooks/useMakeCall";

export function InfluencerMissedCalls() {
  const { data: calls } = useGetCalls();
  const { mutate: makeCall } = useMakeCall();

  const missedCalls = useMemo(
    () =>
      calls
        ?.filter(
          (call) =>
            (!call.influencerStartDate || !call.followerStartDate) &&
            call.endDate
        )
        .sort(
          (a, b) =>
            new Date(b.callStartDate).getTime() -
            new Date(a.callStartDate).getTime()
        ) || [],
    [calls]
  );

  const handlePhoneCall = useCallback(
    (followerId: string) => {
      makeCall(followerId);
    },
    [makeCall]
  );

  return (
    <Table highlightOnHover fontSize="xs">
      <thead>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Call</th>
        </tr>
      </thead>
      <tbody>
        {missedCalls.map((call) => (
          <tr key={call.callId}>
            <td>{call.fullName}</td>
            <td>{new Date(call.callStartDate).toLocaleDateString()}</td>
            <td>
              <Button onClick={() => handlePhoneCall(call.followerId)}>
                <PhoneCall />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

// const ONE_SECOND = 1000;
// const ONE_MINUTE = ONE_SECOND * 60;

// function getTimeDifference(start: string, end: string) {
//   const startDate = new Date(start);
//   const endDate = new Date(end);
//   const difference = endDate.getTime() - startDate.getTime();
//   return difference;
// }

// function getLengthOfTime(start: string, end: string) {
//   const diffTime = getTimeDifference(start, end);
//   const diffMinutes = Math.floor(diffTime / ONE_MINUTE);
//   const diffSeconds = Math.ceil(
//     (diffTime - diffMinutes * ONE_MINUTE) / ONE_SECOND
//   );

//   return `${diffMinutes}m ${diffSeconds}s`;
// }

// function getEarnings(timeInMs: number, rate: number) {
//   const timeInSeconds = timeInMs / ONE_SECOND;
//   if (timeInSeconds < 30) {
//     return 0;
//   }
//   return Math.ceil(timeInMs / ONE_MINUTE) * rate;
// }
