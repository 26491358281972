import { AppShell, Container, Paper, Stack } from "@mantine/core";
import { AuthForgotPassword } from "../../components/AuthForgotPassword";
import { AuthLogin } from "../../components/AuthLogin";
import { AuthRegister } from "../../components/AuthRegister";

type AuthScreenProps = {
  screen: "login" | "signup" | "password";
};

export function AuthScreen({ screen = "login" }: AuthScreenProps) {
  return (
    <AppShell
      padding={0}
      fixed
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colors.gray[9],
        },
      })}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container size="xs" style={{ flex: 1 }}>
          <Paper shadow="xl" p="xl">
            <Stack spacing="xl">
              {screen === "login" && <AuthLogin />}
              {screen === "signup" && <AuthRegister />}
              {screen === "password" && <AuthForgotPassword />}
            </Stack>
          </Paper>
        </Container>
      </div>
    </AppShell>
  );
}
