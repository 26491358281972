import { useCallback, useEffect, useState } from "react";
import { Button, Stack } from "@mantine/core";
import RichTextEditor from "@mantine/rte";
import { useGetUser } from "../../hooks/useGetUser";
import { useUpdateSignupCopy } from "../../hooks/useUpdateSignupCopy";

export function InfluencerRichTextEditor() {
  const { data: userData } = useGetUser();
  const { mutate: updateSignupCopy, isLoading: isSaving } =
    useUpdateSignupCopy();
  const [value, setValue] = useState<string>(userData?.signupCopy || "");
  const [isDefaultValueSet, setIsDefaultValueSet] = useState<boolean>(false);

  const handleChange = useCallback((html: string) => {
    setValue(html);
  }, []);

  const handleSave = useCallback(() => {
    updateSignupCopy(value);
  }, [updateSignupCopy, value]);

  useEffect(() => {
    if (!isDefaultValueSet && userData?.signupCopy) {
      setValue(userData?.signupCopy || "");
      setIsDefaultValueSet(true);
    }
  }, [userData, isDefaultValueSet]);

  return (
    <Stack>
      <RichTextEditor
        key={String(isDefaultValueSet)}
        controls={[["bold", "italic", "underline", "unorderedList"]]}
        value={value}
        onChange={handleChange}
      />
      <Button loading={isSaving} onClick={handleSave}>
        Save
      </Button>
    </Stack>
  );
}
