import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import { useValidateForms } from "../../hooks/useValidateForms";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { PhoneCountryCodeSelect } from "../PhoneCountryCodeSelect";
import { useGetUser } from "../../hooks/useGetUser";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { centsToDollar } from "../../utils/currency";

// FIXME: rate should be separated from these settings and sent to
// an `influencers` endpoint (rather than the `users` endpoint).

export function InfluencerSettings() {
  const { isAuthenticated, isLoading } = useAuth0();

  const { isLoading: isUserDataLoading, data: userData } = useGetUser();

  const { mutate: updateUser, isLoading: isUpdateUserLoading } = useUpdateUser({
    isRateInCents: false,
  });

  const form = useValidateForms({
    name: userData?.fullName,
    phone: userData?.phone,
    email: userData?.email,
    rate: centsToDollar(userData?.rate),
  });

  if (isLoading || isUserDataLoading) {
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    return <p>You are not logged in</p>;
  }

  if (!userData?.fullName) {
    return null;
  }

  return (
    <Container>
      <ModifyContainer>
        <Form>
          <TextInput
            required
            label="Full Name"
            placeholder="E.N. Stan Lee"
            {...form.getInputProps("name")}
            onChange={(e) => {
              form.clearFieldError("name");
              form.setFieldValue("name", e.currentTarget.value);
            }}
            onBlur={() => form.validateField("name")}
          />
          <TextInput
            required
            label="Email"
            placeholder="strongandsmart@hotmail.com"
            {...form.getInputProps("email")}
            onChange={(e) => {
              form.clearFieldError("email");
              form.setFieldValue("email", e.currentTarget.value);
            }}
            onBlur={() => form.validateField("email")}
          />
          <Group>
            <InputWrapper
              label="Phone"
              error={form.getInputProps("phone").error}
              style={{ flex: 1 }}
            >
              <Group>
                <Select
                  itemComponent={PhoneCountryCodeSelect}
                  data={[
                    {
                      countryEmoji: "🇺🇸",
                      value: "1",
                      label: "+1",
                      description: "USA",
                    },
                  ]}
                  value="1"
                  style={{ flexBasis: "96px" }}
                />

                <TextInput
                  required
                  placeholder="(555) 555-5555"
                  {...form.getInputProps("phone")}
                  error={!!form.getInputProps("phone").error}
                  onChange={(e) => {
                    const formattedNumber = formatPhoneNumber(
                      e.currentTarget.value
                    );
                    form.clearFieldError("phone");
                    form.setFieldValue("phone", formattedNumber);
                  }}
                  onBlur={() => form.validateField("phone")}
                  style={{ flex: 1 }}
                />
              </Group>
            </InputWrapper>
            <NumberInput
              required
              label="$/min"
              placeholder="15"
              step={0.01}
              {...form.getInputProps("rate")}
              onChange={(value) => {
                form.clearFieldError("rate");
                // form.setFieldValue("rate", String(value));
                form.setFieldValue("rate", value);
              }}
              onBlur={() => form.validateField("rate")}
              style={{ flex: 0.5 }}
            />
          </Group>
          <Button
            my="md"
            loading={isUpdateUserLoading}
            style={{ gridColumn: "-1 / 1" }}
            onClick={form.onSubmit((variables) => updateUser(variables))}
          >
            Save
          </Button>
        </Form>
      </ModifyContainer>
    </Container>
  );
}

const Container = styled.article`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ModifyContainer = styled.section``;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  font-size: 24px;
`;
