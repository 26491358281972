import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "react-query";
import { Call } from "../types/Call";

export function useGetNextCall() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Call>(
    async () => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        // do nothing
      }

      const res = await fetch(`${process.env.REACT_APP_API_URL}/calls/next`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const call = await res.json();

      return call;
    },
    {
      onSuccess: (data) => {
        if (Object.keys(data).length > 0) {
          queryClient.setQueryData("call", data);
        }
      },
    }
  );
}
