import { useEffect, useMemo, useRef } from "react";
import { useQueryClient } from "react-query";
import { Call } from "../types/Call";
import { useGetCall } from "./useGetCall";
import { useGetCalls } from "./useGetCalls";
import { useGetPendingFollowers } from "./useGetPendingFollowers";

export enum StatusCode {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  PENDING = "PENDING",
}

type CallStatus = {
  isCalling: boolean;
  status: string | null;
  statusCode: StatusCode | null;
};

export function useCallStatus(isGettingNextCall: boolean): CallStatus {
  const { data: call } = useGetCall();
  const isCalling = !!(call && !call?.endDate) || !!isGettingNextCall;
  const { refetch: refetchCalls } = useGetCalls();
  const { refetch: refetchFollowers } = useGetPendingFollowers(isCalling);
  const queryClient = useQueryClient();
  const callTimeout = useRef<number>();

  useEffect(() => {
    if (call?.endDate) {
      refetchCalls();
      refetchFollowers();
      callTimeout.current = window.setTimeout(() => {
        queryClient.setQueryData("call", null);
      }, 10000);
      return;
    }
    clearTimeout(callTimeout.current);
  }, [call, refetchCalls, queryClient, refetchFollowers]);

  return useMemo(
    () => ({
      isCalling,
      ...getCallStatus(call),
    }),
    [call, isCalling]
  );
}

function getCallStatus(call?: Call) {
  if (!call) {
    return {
      status: null,
      statusCode: null,
    } as const;
  }

  if (call.endDate && !call.followerStartDate) {
    return {
      status: `${call.fullName} didn't answer`,
      statusCode: StatusCode.FAIL,
    } as const;
  }

  if (call.endDate && !call.influencerStartDate) {
    return {
      status: "You didn't answer",
      statusCode: StatusCode.FAIL,
    } as const;
  }

  if (call.endDate && call.influencerStartDate && call.followerStartDate) {
    return {
      status: `Call ended with ${call.fullName}`,
      statusCode: StatusCode.SUCCESS,
    } as const;
  }

  if (call.followerStartDate && !call.influencerStartDate) {
    return { status: "Calling you", statusCode: StatusCode.PENDING } as const;
  }

  if (call.influencerStartDate && call.followerStartDate) {
    return {
      status: `In call with ${call.fullName}`,
      statusCode: StatusCode.SUCCESS,
    } as const;
  }

  if (call.callStartDate && !call.followerStartDate) {
    return {
      status: `Calling ${call.fullName}`,
      statusCode: StatusCode.PENDING,
    } as const;
  }

  return {
    status: null,
    statusCode: null,
  };
}
