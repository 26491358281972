import { useAuth0 } from "@auth0/auth0-react";
import {
  AppShell,
  Header,
  Group,
  Text,
  Box,
  Button,
  Container,
  Paper,
  Accordion,
  Space,
  Loader,
  Center,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { InfluencerCallButton } from "../../components/InfluencerCallButton";
import { InfluencerCompletedCalls } from "../../components/InfluencerCompletedCalls";
import { InfluencerLink } from "../../components/InfluencerLink";
import { InfluencerMissedCalls } from "../../components/InfluencerMissedCalls";
import { InfluencerRichTextEditor } from "../../components/InfluencerRichTextEditor";
import { InfluencerSettings } from "../../components/InfluencerSettings";
import { useGetUser } from "../../hooks/useGetUser";

export function MainScreen() {
  // Calling `useGetUser` at this stage allows the app to register the
  // user in the database just once.
  const { data: userData, refetch: refetchGetUser } = useGetUser();
  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [token, setToken] = useState<string | null>(null);
  const isAfterLogin = useMemo(() => {
    return window.location.hash.startsWith("#access_token=");
  }, []);

  useEffect(() => {
    const getAccessToken = async () => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        token = null;
      }
      setToken(token);
    };
    getAccessToken();
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  const isAppLoadedAndLoggedIn = token && userData;
  const isAppLoading = token && !userData;

  // Refetch user if authentication hadn't passed yet.
  useEffect(() => {
    if (isAuthenticated && !userData) {
      refetchGetUser();
    }
  }, [isAuthenticated, refetchGetUser, userData]);

  return (
    <AppShell
      padding={0}
      asideOffsetBreakpoint="sm"
      header={
        <Header
          height="lg"
          p="sm"
          sx={(theme) => ({
            border: "none",
            backgroundColor: theme.colors.gray[3],
            alignItems: "end",
          })}
        >
          <Box
            sx={(theme) => ({
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
            })}
          >
            <Text
              weight="bold"
              styles={(theme) => ({
                root: {
                  border: "none",
                  color: theme.colors.gray[3],
                  margin: "auto 0",
                },
              })}
            >
              maybe call me
            </Text>
            {((isAuthenticated && !isAuth0Loading) || isAfterLogin) && (
              <Logout />
            )}
            {!isAuthenticated && !isAuth0Loading && !isAfterLogin && <Login />}
          </Box>
        </Header>
      }
      fixed
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colors.gray[3],
        },
      })}
    >
      {isAppLoading && (
        <Center style={{ height: "100%" }}>
          <Loader />
        </Center>
      )}

      {isAppLoadedAndLoggedIn && (
        <>
          <Box
            mt="xl"
            sx={(theme) => ({
              padding: `${theme.spacing.xl * 3}px 0`,
            })}
          >
            <InfluencerCallButton />
          </Box>

          <Container size="sm">
            <InfluencerLink />
          </Container>

          <Space h="xl" />

          <Container size="sm">
            <Paper radius="md" p="xs">
              <Accordion iconPosition="right" initialItem={0}>
                <Accordion.Item label="Settings">
                  <InfluencerSettings />
                </Accordion.Item>

                <Accordion.Item label="Edit Signup Copy">
                  <InfluencerRichTextEditor />
                </Accordion.Item>

                <Accordion.Item label="Completed Calls">
                  <InfluencerCompletedCalls />
                </Accordion.Item>

                <Accordion.Item label="Missed Calls">
                  <InfluencerMissedCalls />
                </Accordion.Item>
              </Accordion>
            </Paper>
          </Container>
          <Space h="xl" />
        </>
      )}

      {!token && !isAfterLogin && (
        <Box
          mt="xl"
          sx={(theme) => ({
            padding: `${theme.spacing.xl * 3}px 0`,
            textAlign: "center",
          })}
        >
          <Text size="xl">You are not logged in.</Text>
        </Box>
      )}
    </AppShell>
  );
}

function Login() {
  return (
    <Group align="center">
      <Button
        variant="outline"
        uppercase
        component="a"
        href="/auth/login"
        styles={(theme) => ({
          root: {
            fontSize: "0.8rem",
            border: "none",
            color: theme.colors.gray[7],
          },
        })}
      >
        Log In
      </Button>
      <Button
        variant="outline"
        uppercase
        component="a"
        href="/auth/register"
        styles={(theme) => ({
          root: {
            fontSize: "0.8rem",
            border: "none",
            color: theme.colors.gray[7],
          },
        })}
      >
        Sign Up
      </Button>
    </Group>
  );
}

const Logout = () => {
  const { logout } = useAuth0();

  return (
    <Button
      compact
      variant="outline"
      onClick={() => logout({ returnTo: window.location.origin })}
      uppercase
      styles={(theme) => ({
        root: {
          fontSize: "0.8rem",
          border: "none",
          color: theme.colors.gray[7],
        },
      })}
    >
      Log Out
    </Button>
  );
};
