import "./App.css";
import { Route, Routes } from "react-router-dom";

import { AdminScreen } from "./screens/AdminScreen";
import { CheckoutScreen } from "./screens/CheckoutScreen";
import { MainScreen } from "./screens/MainScreen";
import { AuthScreen } from "./screens/AuthScreen";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainScreen />} />
      <Route path="/auth/login" element={<AuthScreen screen="login" />} />
      <Route path="/auth/register" element={<AuthScreen screen="signup" />} />
      <Route path="/auth/password" element={<AuthScreen screen="password" />} />
      <Route path="/signup/:friendlyId" element={<CheckoutScreen />} />
      <Route path="/admin" element={<AdminScreen />} />
      <Route path="*" element={<MainScreen />} />
    </Routes>
  );
}

export default App;
