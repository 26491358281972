import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";

export function useUpdateSignupCopy() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(async (signupCopy: string) => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      // do nothing
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ signupCopy }),
    });
    return res.json();
  });
}
