import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

export function useGetUser() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  return useQuery("user", async () => {
    if (!isAuthenticated) {
      return null;
    }

    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      // do nothing
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.json();
  });
}
