import { AppShell } from "@mantine/core";
import { Checkout } from "../../components/Checkout";

export function CheckoutScreen() {
  return (
    <AppShell
      padding={0}
      fixed
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Checkout />
    </AppShell>
  );
}
