import { useForm } from "@mantine/form";

type FormTypeValues = "name" | "email" | "phone" | "rate";

export type UseFormTypes = {
  name?: string;
  email?: string;
  phone?: string;
  rate?: number;
};

const validators = {
  name: (value: string) =>
    /(.|\s)*\S(.|\s)*/.test(value) ? null : "Please enter a valid name",
  phone: (value: string) =>
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
      ? null
      : "Please enter a valid phone number",
  email: (value: string) =>
    /^\S+@\S+$/.test(value) ? null : "Please enter a valid email",
  rate: (value: number) => (value ? null : "Please enter a valid rate"),
};

export function useValidateForms(initialValues: UseFormTypes) {
  const formTypes = Object.keys(initialValues);
  const validate = Object.fromEntries(
    formTypes.map((formType) => [
      formType,
      validators[formType as FormTypeValues],
    ])
  );

  return useForm<UseFormTypes>({
    initialValues,
    validate,
  });
}
